#mobile-view {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

#mobile-container {
  width: 100%;
  height: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}
